import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, FormInstance, Input, Row } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ClaimTable from './ClaimTable';

interface ClaimFormProps {
	form: FormInstance;
	onGeneratePdf: () => void;
}

const ClaimForm: React.FC<ClaimFormProps> = ({ form, onGeneratePdf }) => {
	return (
		<div className="p-5">
			<div id="header">
				<div className="logo w-[200px]">
					<img src="Wonsys.png" alt="Wonsys Logo" className="w-full" />
				</div>
				<div className="mt-3 address">
					<p className="text-[11px]">B-3-13 PLAZA ARKADIA, NO.3, JALAN INTISARI PERDANA, DESA PARKCITY, 52200 KUALA LUMPUR.</p>
				</div>
				<div className="flex justify-end mt-5 title">
					<h1 className="text-3xl font-[500]">CLAIM FORM</h1>
				</div>
			</div>
			<Divider />
			<div id="applicant_profile">
				<Form form={form} layout="vertical">
					<Row gutter={[16, 0]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Form.Item label="Name as per NRIC / Passport" name="name" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Department" name="department" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Designation" name="designation" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email', message: 'Invalid Email' }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Contact Number" name="contactNumber" rules={[{ required: true }]}>
								<PhoneInput country="my" />
							</Form.Item>
						</Col>
					</Row>
					<Divider />
					<div id="claim_table">
						<Form.Item name="claim">
							<ClaimTable />
						</Form.Item>
					</div>
				</Form>
			</div>
			<div className="flex justify-end">
				<Button size="large" type="primary" onClick={onGeneratePdf} icon={<FilePdfOutlined />}>
					Generate PDF
				</Button>
			</div>
		</div>
	);
};

export default ClaimForm;
